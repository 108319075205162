var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"frame px-0 pt-0",attrs:{"id":"cart-review"}},[[_c('div',{staticClass:"carrier-discount-teste-ab",class:[_vm.isLateralMenu ? '' : '']},[(_vm.cart && !_vm.cart.is_only_virtual_products)?_c('CartShipping',{staticClass:"frame px-0",attrs:{"enableExpressShipping":_vm.enableExpressShipping,"loading":_vm.loading,"cart":_vm.cart,"cart-has-prime":_vm.cart.has_prime,"is-lateral-menu":_vm.isLateralMenu,"is-mobile":_vm.isMobile},on:{"loading-cart":function($event){return _vm.$emit('loading-cart')},"done-update-cart":function($event){return _vm.$emit('done-update-cart')},"free-shipping-calc-obj":function($event){return _vm.updateFreeShippingCalcObj($event)}}}):_vm._e(),_vm._v(" "),_c('CartDiscount',{attrs:{"cart":_vm.cart,"is-lateral-menu":_vm.isLateralMenu,"is-mobile":_vm.isMobile},on:{"update-cart":function($event){return _vm.updateCart()},"loading-cart":function($event){return _vm.$emit('loading-cart')},"done-update-cart":function($event){return _vm.$emit('done-update-cart')}}}),_vm._v(" "),(_vm.cart.total_cashback_allowed_for_use > 0)?_c('div',{staticClass:"cart-cashback-toggle text-uppercase d-flex flex-justify-between mt-3"},[_c('p',{staticClass:"mx-0 my-0"},[_vm._v("\n                        Usar saldo de cashback:\n                        "+_vm._s(_vm._f("formatMoney")(_vm.cart.total_cashback_allowed_for_use))+"\n                        "),(
                                _vm.$scope.cashback_available > _vm.cart.total_cashback_allowed_for_use
                            )?_c('i',{staticClass:"icon-cr icon-info cursor-pointer",staticStyle:{"font-size":"1.2em"},on:{"click":function($event){_vm.cashbackRulesModal = true}}}):_vm._e()]),_vm._v(" "),_c('input',{staticClass:"cr-switch",attrs:{"id":"use-cashback-switch","type":"checkbox"},domProps:{"checked":_vm.cart.use_cashback},on:{"change":_vm.toggleCashback}}),_vm._v(" "),_c('label',{staticClass:"d-inline-block",attrs:{"for":"use-cashback-switch"}})]):_vm._e()],1)],_vm._v(" "),(_vm.cart)?[_c('div',{staticClass:"values"},[_c('div',{staticClass:"d-flex flex-align-center"},[_c('p',{staticClass:"ml-0 my-0 mr-auto",staticStyle:{"font-size":"15px"}},[_vm._v("PRODUTOS")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-right mx-0 my-0",staticStyle:{"font-size":"15px"}},[_c('span',[_vm._v(_vm._s(_vm.$f.formatMoney(_vm.cart.total_products)))])])])]),_vm._v(" "),(_vm.cart.total_discounts > 0)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"ml-0 my-0 mr-auto",staticStyle:{"font-size":"15px"}},[_vm._v("DESCONTO")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"mx-0 my-0",staticStyle:{"font-size":"15px"}},[_c('span',[_vm._v("- "+_vm._s(_vm.$f.formatMoney(_vm.cart.total_discounts)))])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"text-right",attrs:{"id":"cart-finish-price"}},[_c('p',{staticClass:"ml-0 my-0 mr-auto",staticStyle:{"font-size":"15px"}},[_c('strong',[_vm._v("\n                                "+_vm._s(_vm.$f.formatMoney(
                                        Math.max(0, _vm.cart.total_products - _vm.cart.total_discounts)
                                    ))+"\n                            ")])]),_vm._v(" "),(
                                Math.max(0, _vm.cart.total_products - _vm.cart.total_discounts) >= 20 &&
                                _vm.$scope.IS_REGULAR_CUSTOMER &&
                                _vm.$f.formatCreditCardInstallments(
                                    _vm.cart.total_products - _vm.cart.total_discounts
                                )
                            )?_c('p',{staticClass:"text-secondary mx-0 my-0 text-yanone hidden-xs-down"},[_vm._v("\n                            Ou\n                            "+_vm._s(_vm.$f.formatCreditCardInstallments(
                                    _vm.cart.total_products - _vm.cart.total_discounts
                                ))+"\n                            sem juros\n                            "),_c('br'),_vm._v(" "),(
                                    _vm.$scope.CONFIG['CREDIT_CARD_MINIMUN_INSTALLMENT_VALUE'] > 5
                                )?_c('small',[_vm._v("(Parcela mínima: R$ 30)")]):_vm._e()]):_vm._e()])])]),_vm._v(" "),(_vm.$scope.IS_REGULAR_CUSTOMER && _vm.cart.total_cashback_generated > 0)?_c('div',{staticClass:"text-uppercase d-flex flex-align-center mt-4 mb-3"},[_c('i',{staticClass:"icon-cr icon-cashback mr-1 text-cashback",staticStyle:{"font-size":"20px"}}),_vm._v(" "),_c('p',{staticClass:"text-cashback"},[_vm._v("\n                    Essa compra gera "+_vm._s(_vm._f("formatMoney")(_vm.cart.total_cashback_generated))+" de\n                    cashback\n                ")])]):_vm._e(),_vm._v(" "),(_vm.isLateralMenu)?_c('div',{staticClass:"finish-cart-AB fixed-button",class:[_vm.showButton ? 'positioned-button' : '']},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin":"1rem"}},[_c('p',{staticClass:"ml-0 my-0 mr-auto mt-2",staticStyle:{"font-size":"14px","color":"gray"}},[_vm._v("\n                        TOTAL\n                    ")]),_vm._v(" "),_c('div',[_c('p',{staticClass:"ml-0 my-0 mr-auto",staticStyle:{"font-size":"16px"}},[_c('strong',[_vm._v("\n                                "+_vm._s(_vm.$f.formatMoney(
                                        Math.max(0, _vm.cart.total_products - _vm.cart.total_discounts)
                                    ))+"\n                            ")])])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-xl btn-wide mt",on:{"click":function($event){return _vm.$emit('finish-order')}}},[_vm._v("\n                    "+_vm._s(_vm.$t('_shop.toFinishOrder'))+"\n                    "),_vm._m(1)])]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"cart-finish"}},[(_vm.cart.enable_checkout)?[(_vm.isLateralMenu)?_c('div',{staticClass:"d-flex flex-justify-around flex-align-center mt-4"},[_c('div',{staticClass:"flex-item-grow"},[_c('button',{staticClass:"btn btn-wide d-flex flex-justify-center flex-align-center",staticStyle:{"background-color":"black","color":"white","font-size":"13px","border":"1px solid","border-radius":"unset"},on:{"click":function($event){return _vm.$emit('finish-order')}}},[_c('div',{staticClass:"mr-2"},[_vm._v("\n                                    "+_vm._s(_vm.$t('_shop.toFinishOrder'))+"\n                                ")]),_vm._v(" "),_vm._m(2)])])]):_vm._e(),_vm._v(" "),(!_vm.isLateralMenu)?_c('button',{staticClass:"btn btn-xl btn-wide mt",on:{"click":function($event){return _vm.$emit('finish-order')}}},[_vm._v("\n                        "+_vm._s(_vm.$t('_shop.toFinishOrder'))+"\n                        "),_vm._m(3)]):_vm._e()]:[(_vm.$scope.IS_REGULAR_CUSTOMER)?_c('Alert',{attrs:{"alert":_vm.blockCheckoutAlert}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex flex-justify-around flex-align-center mt-3"},[_c('div',{staticClass:"flex-item-grow"},[_c('button',{staticClass:"btn btn-wide btn-xl",attrs:{"disabled":""}},[_vm._v("\n                                "+_vm._s(_vm.$t(
                                        _vm.$scope.IS_REGULAR_CUSTOMER
                                            ? '_shop.toFinishOrder'
                                            : '_shop.minimumValueNotReached'
                                    ))+"\n                                "),_c('span',{staticClass:"ml-3",staticStyle:{"font-size":"18px"}},[_vm._v("→")])])])])],_vm._v(" "),(!_vm.isLateralMenu)?[_c('a',{attrs:{"href":"/"}},[_c('button',{staticClass:"btn btn-wide mt",staticStyle:{"background-color":"white","color":"black","font-size":"13px","border":"1px solid","border-radius":"unset"},on:{"click":function($event){}}},[_vm._v("\n                            "+_vm._s(_vm.$t('_shop.toKeepBuying'))+"\n                        ")])]),_vm._v(" "),(!_vm.isMobile)?_c('a',{staticClass:"text-right text-yanone mt pos-relative mt-2 carrier-international",on:{"click":function($event){return _vm.$emit('change-shipping-international')}}},[_vm._m(4)]):_vm._e()]:_vm._e()],2)]:_vm._e()],2),_vm._v(" "),_c('Modal',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Regulamento Cashback ")]},proxy:true}]),model:{value:(_vm.cashbackRulesModal),callback:function ($$v) {_vm.cashbackRulesModal=$$v},expression:"cashbackRulesModal"}},[_vm._v(" "),_c('div',{staticClass:"card-block"},[_c('CashbackFaq',{attrs:{"is-hotsite":false}})],1)]),_vm._v(" "),(!_vm.isLateralMenu)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],staticClass:"finish-cart-AB fixed-button",class:[_vm.showButton ? 'positioned-button' : '']},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin":"1rem"}},[_c('p',{staticClass:"ml-0 my-0 mr-auto mt-2",staticStyle:{"font-size":"14px","color":"gray"}},[_vm._v("TOTAL")]),_vm._v(" "),_c('div',[_c('p',{staticClass:"ml-0 my-0 mr-auto",staticStyle:{"font-size":"16px"}},[_c('strong',[_vm._v("\n                        "+_vm._s(_vm.$f.formatMoney(
                                Math.max(0, _vm.cart.total_products - _vm.cart.total_discounts)
                            ))+"\n                    ")])])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-xl btn-wide mt",on:{"click":function($event){return _vm.$emit('finish-order')}}},[_vm._v("\n            "+_vm._s(_vm.$t('_shop.toFinishOrder'))+"\n            "),_vm._m(5)])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ml-0 my-0 mr-auto mt-2",staticStyle:{"font-size":"15px"}},[_c('strong',[_vm._v("TOTAL")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"animation-go-back icon-cr icon-arrow-right ml-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"animation-go-back icon-cr icon-arrow-right ml-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"animation-go-back icon-cr icon-arrow-right ml-2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',{staticClass:"cart-security text-underline"},[_vm._v("\n                                Sua entrega é fora do Brasil?\n                            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"animation-go-back icon-cr icon-arrow-right ml-2"})])
}]

export { render, staticRenderFns }