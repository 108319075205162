<template>
    <div>
        <div
            id="product-about-collapsible"
            :class="{ opened: expandContent }"
            class="collapsible"
            @click="expandContent = !expandContent"
        >
            <!-- DESCRIPTION -->
            <template v-if="hasDescription">
                <h3 class="mt text-uppercase">
                    {{ title }}
                </h3>
                <p itemprop="description" v-html="$f.removeInlineStyles(product.description)"></p>
            </template>

            <ProductCredit :partner="partner" :isMobile="false" />

            <!-- INFO -->
            <h3 class="mt text-uppercase">
                {{ $t('_product.about') }}
            </h3>
            <div v-if="aboutText" v-html="aboutText" />

            <template v-if="(product.partner || {}).url || product.categories.length > 0">
                <h3 class="mt text-uppercase">
                    {{ $t('_product.related') }}
                </h3>
                <ProductCategories id="product-categories-mobile" :product="product" />
            </template>
        </div>

        <p class="text-accent cursor-pointer mb-4" @click="expandContent = !expandContent">
            {{ $t(expandContent ? 'toSeeLess' : 'toSeeMore') }}
            <i
                :class="{
                    'icon-chevron-down-2': !expandContent,
                    'icon-chevron-up-2': expandContent,
                }"
                class="icon-cr primary"
            />
        </p>

        <!--        <div id="product-campaign" class="d-flex">-->
        <!--            <i id="product-campaign-badge" class="flex-child-fixed"></i>-->
        <!--            <div>-->
        <!--                <h3 class="mt-0">Camisetas mudam o mundo</h3>-->
        <!--                <p class="mb-0">-->
        <!--                    <a class="text-accent" href="/camisetas-mudam-o-mundo">-->
        <!--                        {{ $t('_marketing.learnMoreProjects') }}-->
        <!--                    </a>-->
        <!--                </p>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import Modal from '../common/container/Modal';
    import ProductAbout from './mixin/ProductAbout';
    import ProductCredit from './ProductCredit';

    export default {
        name: 'ProductAboutForWholesaler',

        components: { Modal, ProductCredit },
        props: {
            partner: {
                type: Object,
                required: true,
            },
        },

        mixins: [ProductAbout],

        data() {
            return {
                expandContent: false,
            };
        },

        // methods: {
        //     stopVideo() {
        //         let iframe = cr$.byId('product-video').raw;
        //         iframe.contentWindow.postMessage(
        //             '{"event":"command","func":"stopVideo","args":""}',
        //             '*'
        //         );
        //     },
        // },
    };
</script>
