import { EventBus } from './common';
import cr$ from './crquery';
import { CLOSE_DESKTOP_MENU_EVENT, SHOW_MOBILE_MENU_EVENT } from '../vue/events';
import throttle from 'lodash/throttle';

const initHeader = () => {
    const desktopHeaderEl = cr$.byId('header-desktop');
    if (desktopHeaderEl.exists()) {
        window.addEventListener(
            'scroll',
            throttle(
                () => {
                    EventBus.$emit(CLOSE_DESKTOP_MENU_EVENT);
                },
                500,
                {
                    leading: true,
                    trailing: true,
                }
            ),
            { passive: true }
        );
    }

    const headerDrawerEl = cr$.byId('header-drawer-icon');
    if (headerDrawerEl.exists()) {
        // open drawer on icon click
        headerDrawerEl.click(() => EventBus.$emit(SHOW_MOBILE_MENU_EVENT));
    }
};

cr$.ready(function () {
    requestIdleCallback(() => {
        initHeader();
    });
});
