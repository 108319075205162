var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"lazy-hover product-list-item font-roboto-c",attrs:{"itemscope":"","itemtype":"https://schema.org/Product"},on:{"click":function($event){return _vm.$emit('click', $event)},"mouseover":function($event){_vm.showAddInList = true},"mouseleave":function($event){_vm.showAddInList = false}}},[_c('meta',{attrs:{"content":"Chico Rei","itemprop":"brand"}}),_vm._v(" "),_c('meta',{attrs:{"content":_vm.product.id ? _vm.product.id : _vm.product._id,"itemprop":"sku"}}),_vm._v(" "),_c('a',{class:_vm.actualFabrics ? 'link_product' : '',style:({
            opacity: _vm.productAddedToCartId && _vm.productAddedToCartId !== _vm.product.id ? '0.3' : '1',
        }),attrs:{"content":_vm.product.link,"href":_vm.productLink,"itemprop":"url","target":"_self"}},[_c('div',{staticClass:"overflow-hidden aspect bg-default-1 product-list-item-image-hover",class:{
                'aspect-xs-4-5': _vm.shorterImage,
                'aspect-shirt': !_vm.shorterImage,
            },attrs:{"rel":"1"}},[_c('div',{staticClass:"pos-relative",class:{ 'no-pointer-events': _vm.isWishlist }},[(_vm.eagerLoadImage || _vm.showFirstImage)?_c('img',{staticClass:"product-list-item-photo w-full d-block",class:{
                        'h-full': _vm.shorterImage && _vm.product.type.id !== 1,
                    },attrs:{"alt":_vm.product.name,"src":_vm.image,"border":"0","itemprop":"image","width":"290","height":"435"}}):_c('img',{staticClass:"product-list-item-photo lazy lazy-fade w-full",class:{
                        'h-full': _vm.shorterImage && _vm.product.type.id !== 1,
                    },attrs:{"alt":_vm.product.name,"data-src":_vm.image,"border":"0","itemprop":"image","width":"290","height":"435","src":""}}),_vm._v(" "),(!_vm.hideArtImage)?_c('img',{staticClass:"product-list-item-art lazy lazy-fade",attrs:{"alt":'Estampa ' + _vm.product.name,"data-src":_vm.imageArt,"border":"0","src":"","width":"290","height":"435"}}):_vm._e(),_vm._v(" "),(!_vm.hideArtImage && _vm.internalSelectedFabricId)?_c('img',{staticClass:"product-list-item-art",attrs:{"alt":'Estampa ' + _vm.product.name,"src":_vm.imageArt,"border":"0","width":"290","height":"435"}}):_vm._e(),_vm._v(" "),(_vm.product.in_stock && _vm.product.variations && _vm.showAddInList)?_vm._t("list-add-to-cart-desktop",null,{"selectedFabricId":_vm.selectedFabricId}):_vm._e(),_vm._v(" "),(_vm.product.in_stock && _vm.product.variations)?_c('div',{staticClass:"icon-add-to-cart hidden-xs-up",on:{"click":function($event){$event.preventDefault();return _vm.$emit('show-add-to-cart-mobile', {
                            product: _vm.product,
                            fabricId: _vm.selectedFabricId,
                        })}}},[_c('i',{staticClass:"icon-cr icon-list-add-to-cart"})]):_vm._e(),_vm._v(" "),(!_vm.isWishlist && !_vm.options.hideSeals)?_c('div',{staticClass:"tag-new"},[_c('ProductListItemSeal',{attrs:{"small":_vm.small,"product":_vm.product,"show-new-seal":true}})],1):_vm._e()],2),_vm._v(" "),(_vm.isWishlist)?_c('div',{staticClass:"tag-new"},[_vm._t("outside-link")],2):_vm._e()]),_vm._v(" "),(_vm.options.displayColors && _vm.actualFabrics && _vm.actualFabrics.length > 1)?_c('div',{staticClass:"product-list-item-colors mt-3 mb-1",staticStyle:{"width":"fit-content"},on:{"click":function($event){$event.preventDefault();}}},_vm._l((_vm.actualFabrics),function(fabric,index){return _c('div',{staticClass:"product-list-item-color",class:{
                    'hidden-xs-down': index > 5,
                    'with-border': fabric.id === _vm.selectedFabricId,
                },style:({
                    'background-color': fabric.product_color_hexa,
                }),on:{"click":function($event){$event.preventDefault();_vm.internalSelectedFabricId = fabric.id}}})}),0):_vm._e(),_vm._v(" "),(!_vm.options || _vm.options.displayName)?_c('p',{class:{
                'product-list-item-title': !_vm.isWishlist,
                'edit-iOS': _vm.isIOS,
            },attrs:{"itemprop":"name"}},[_vm._v("\n            "+_vm._s(_vm.product.name)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.isWishlist)?_c('div',[_vm._t("color-size")],2):_c('meta',{attrs:{"content":_vm.product.name,"itemprop":"name"}}),_vm._v(" "),_c('div',{staticClass:"product-list-item-info",attrs:{"itemprop":"offers","itemscope":"","itemtype":"http://schema.org/Offer"}},[_c('link',{attrs:{"href":_vm.product.link,"itemprop":"url"}}),_vm._v(" "),_c('link',{attrs:{"href":"http://schema.org/NewCondition","itemprop":"itemCondition"}}),_vm._v(" "),(_vm.options && _vm.options.displayPrice && _vm.product.in_stock)?[_c('ProductCatalogPrice',{attrs:{"options":_vm.options,"product":_vm.product,"show-promo":_vm.displayPromoBadge}})]:(_vm.options && _vm.options.displayPrice)?[_c('meta',{attrs:{"content":_vm.product.price_min > 0 ? _vm.product.price_min : _vm.product.price,"itemprop":"price"}}),_vm._v(" "),_c('meta',{attrs:{"content":"BRL","itemprop":"priceCurrency"}}),_vm._v(" "),_c('p',[_vm._v("Esgotado")])]:[_c('meta',{attrs:{"content":_vm.product.price_min > 0 ? _vm.product.price_min : _vm.product.price,"itemprop":"price"}}),_vm._v(" "),_c('meta',{attrs:{"content":"BRL","itemprop":"priceCurrency"}})],_vm._v(" "),_c('link',{attrs:{"href":'http://schema.org/' +
                    (_vm.product.quantity > 0 || _vm.product.virtual_stock ? 'InStock' : 'OutOfStock'),"itemprop":"availability"}})],2),_vm._v(" "),(
                _vm.product.promo_combo &&
                !_vm.isWishlist &&
                _vm.options &&
                _vm.options.displayPrice &&
                !_vm.options.hideSeals
            )?_c('div',{staticClass:"mb mt"},[_c('ProductListItemSeal',{attrs:{"small":_vm.small,"product":_vm.product,"show-discount":!_vm.options.displayPercent && !_vm.options.hidePromoBadge,"show-promo":_vm.displayPromoBadge}})],1):_vm._e(),_vm._v(" "),_vm._t("default")],2),_vm._v(" "),_vm._t("below-image")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }