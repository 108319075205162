<template>
    <div v-if="shippingValue && nationalShipping">
        <Alert :alert="progressBarAlert" />
        <p v-if="cartHasPrime || $scope.is_prime" class="d-flex flex-align-center">
            Cliente
            <img
                src="https://chico-rei.imgix.net/images/site/2021/prime/selo_prime_02_378bcb.svg"
                alt="Chico Rei Prime"
                style="width: 3.5rem; height: 1rem; vertical-align: text-bottom"
            />
            tem direito a frete grátis, aproveite!
        </p>

        <template
            v-else-if="(remaining <= 0 || cartHasNoShippingCost) && nationalShipping && !loading"
        >
            <p class="text-center">
                Parabéns, você garantiu seu <strong>frete grátis</strong> para o CEP
                <strong>{{ shippingZip }}</strong
                >! :) <i class="icon-cr icon-carrier-car"></i>
            </p>
            <div class="frame-outline py-1">
                <div class="progress-scroll">
                    <div v-bind:style="{ width: '100%' }"></div>
                </div>
            </div>
        </template>

        <template v-else-if="!loading">
            <p class="text-center">
                Faltam {{ $f.formatMoney(remaining) }} para o <strong>FRETE GRÁTIS</strong> para o
                CEP <strong>{{ shippingZip }}</strong> <i class="icon-cr icon-carrier-car"></i>
            </p>
            <div class="frame-outline py-1">
                <div class="progress-scroll">
                    <div v-bind:style="{ width: progress + '%' }"></div>
                </div>
            </div>
        </template>
        <template v-else-if="loading">
            <Spinner />
        </template>
    </div>
</template>

<script>
    import Alert from '../common/Alert';
    import { getScope } from '../../../common/common';
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';

    export default {
        name: 'FreeShippingProgress',
        props: ['cart', 'isLateralMenu', 'loading', 'nationalShipping', 'calculatedShippingObj'],

        components: { Alert, Spinner },

        data() {
            return {
                valor: 30,
                cepError: null,
                progressBarAlert: null,
            };
        },

        computed: {
            shippingZip() {
                return (this.calculatedShippingObj || {}).cep || this.cart.zip_code_free_shipping;
            },

            shippingIsjf() {
                return (this.calculatedShippingObj || {}).is_jf || this.cart.is_jf_free_shipping;
            },

            shippingValue() {
                return (this.calculatedShippingObj || {}).min_shipping || this.cart.min_shipping;
            },

            cartHasNoShippingCost() {
                return (
                    (this.calculatedShippingObj || {}).has_free_shipping ||
                    this.cart.has_free_shipping
                );
            },

            cartHasPrime() {
                return this.cartHasNoShippingCost && this.cart.has_prime;
            },

            progress() {
                return (this.cart.to_be_paid / this.shippingValue) * 100 > 100
                    ? 100
                    : (this.cart.to_be_paid / this.shippingValue) * 100;
            },
            remaining() {
                return this.shippingValue - this.cart.to_be_paid;
            },
        },

        methods: {},
    };
</script>
