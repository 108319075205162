<template>
    <div class='pl-0 ml-0'>
        <ProductTitle :product="product" :isMobile="false" />

        <div class="clear"></div>
        <ProductPageComboPrice v-if="isCombo" class="mb"/>
        <ProductPageRegularPrice
            v-else-if="(product.quantity > 0 || product.virtual_stock) && canBeBought"
            :product="product"
            :sizeToBuy="sizeToBuy"
            :isMobile="false"
            class="text-yanone"
            @scroll-to-wholesaler-buy="$emit('scroll-to-wholesaler-buy')"
        />
        <div
            v-if="product.cashback_percentage && $scope.IS_REGULAR_CUSTOMER"
            class="hidden-md-down"
        >
            <div class="text-uppercase cashback-text d-flex flex-align-center mt">
                <i class="icon-cr icon-cashback mr-2"></i>
                Essa compra garante {{ product.cashback_percentage }}% de
                <a @click.prevent="rulesModal = true" class="text-underline ml-1 cashback-text"
                    >cashback</a
                >
            </div>
        </div>

        <div v-if="product.id === 16666" class="text-yanone">
            <hr />
            <p class="text-info text-uppercase">Vendas encerradas: muito obrigado!</p>
            <p>
                <a
                    class="text-accent"
                    href="https://chicorei.com/camiseta/camiseta-voce-pra-sempre-em-mim-16841.html"
                >
                    Clique aqui e confira a nova campanha Tiago Iorc + Chico Rei
                </a>
            </p>
        </div>

        <template v-if="productTypeInfo">
            <hr />
            <p class="text-yanone">
                <i :class="[productTypeInfo.icon]" class="icon-cr"></i> &nbsp;{{
                    productTypeInfo.label
                }}
            </p>
        </template>

        <Modal v-model="rulesModal">
            <template v-slot:title> Regulamento</template>
            <div class="card-block">
                <CashbackFaq :is-hotsite="false" />
            </div>
        </Modal>
    </div>
</template>

<script>
    import { timeHandler } from '../../../common/common';
    import { productStore } from '../../common/store/product-store';
    import Countdown from '../common/Countdown';
    import Modal from '../common/container/Modal';
    import ProductPageComboPrice from './ProductPageComboPrice';
    import ProductPageRegularPrice from './ProductPageRegularPrice';
    import ProductTitle from './ProductTitle';
    import CashbackFaq from '../cashback/CashbackFaq.vue';

    export default {
        name: 'ProductMainInfo',

        components: {
            CashbackFaq,
            ProductPageComboPrice,
            Modal,
            ProductPageRegularPrice,
            Countdown,
            ProductTitle,
        },

        props: {
            canBeBought: {
                type: Boolean,
                required: true,
            },
            partner: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                productStoreState: productStore.state,
                rulesModal: false,
            };
        },

        computed: {
            hasVariations() {
                return this.product.variations.length > 0;
            },

            product() {
                return this.productStoreState.product;
            },

            sizeToBuy() {
                return this.productStoreState.sizeToBuy;
            },

            wholesalerSizesQuantitiesToBuy: {
                get() {
                    return this.productStoreState.wholesalerSizesQuantitiesToBuy;
                },

                set(val) {
                    this.productStoreState.wholesalerSizesQuantitiesToBuy = val;
                },
            },

            showWishlist: {
                get() {
                    return this.productStoreState.showWishlist;
                },

                set(val) {
                    this.productStoreState.showWishlist = val;
                },
            },

            productTypeInfo() {
                switch ((this.product.type || {}).url) {
                    case 'almofada':
                        return {
                            icon: 'icon-pillow',
                            label: 'Medidas 40 X 40 cm*',
                        };
                    case 'caneca':
                        return {
                            icon: 'icon-mug',
                            label: 'Capacidade 325 ml',
                        };
                    case 'lenco':
                        return {
                            icon: 'icon-scarf',
                            label: 'Medidas 80 x 80cm*',
                        };
                    case 'ecobag':
                        return {
                            icon: 'icon-ecobag',
                            label: 'Medidas 34 x 27,5cm',
                        };
                    default:
                        return null;
                }
            },

            isCombo() {
                return (
                    this.product.promo_instruction &&
                    this.product.promo_combo_unit_price &&
                    [25, 28].includes(this.product.promo_combo)
                );
            },
        },
    };
</script>
