<template>
    <div class="flex-grid">
        <!-- INFO -->
        <div class="flex-xs-12 flex-md-12 p0">
            <template v-if="hasDescription">
                <ProductCredit :partner="partner" :isMobile="true" />

                <h3
                    class="h6 text-bold text-uppercase mt font-roboto-c grayColor m0"
                    @click="collapseItens.description = !collapseItens.description"
                >
                    <i
                        class="icon-cr collapsible-icon hidden-md-up"
                        :class="
                            collapseItens.description ? 'icon-chevron-right' : 'icon-chevron-down-2'
                        "
                    ></i>
                    {{ title }}
                </h3>
                <p
                    class="grayColor m0"
                    :class="{ opened: collapseItens.description }"
                    itemprop="description"
                    v-html="$f.removeInlineStyles(product.description)"
                ></p>
            </template>

            <!-- <div id="product-campaign" class="d-flex">
                <i id="product-campaign-badge" class="flex-child-fixed"></i>
                <div>
                    <h3 class="mt-0">Camisetas mudam o mundo</h3>
                    <p class="mb-0">
                        <a class="text-accent" href="/camisetas-mudam-o-mundo">
                            {{ $t('_marketing.learnMoreProjects') }}
                        </a>
                    </p>
                </div>
            </div> -->
        </div>
        <ProductCredit :partner="partner" :isMobile="false" />

        <!-- DESCRIPTION -->
        <div class="flex-xs-12 flex-md-12 p0">
            <div class="flex-grid">
                <div class="flex-xs-12 flex-lg-6 p0">
                    <h3
                        class="h6 text-bold text-uppercase font-roboto-c grayColor m0"
                        @click="collapseItens.aboutProduct = !collapseItens.aboutProduct"
                    >
                        <i
                            class="icon-cr collapsible-icon hidden-md-up"
                            :class="
                                collapseItens.aboutProduct
                                    ? 'icon-chevron-right'
                                    : 'icon-chevron-down-2'
                            "
                        ></i>
                        {{ $t('_product.about') }}
                    </h3>

                    <div
                        class="grayColor m0"
                        :class="{ opened: collapseItens.aboutProduct }"
                        v-if="aboutText"
                        v-html="aboutText"
                    />

                    <!--                    <p-->
                    <!--                        class="grayColor m0"-->
                    <!--                        :class="{ opened: collapseItens.aboutProduct }"-->
                    <!--                        v-if="-->
                    <!--                            [18, 114, 119, 133].indexOf(-->
                    <!--                                product.images_detail-->
                    <!--                            ) !== -1 && (product.type || {}).id === 1-->
                    <!--                        "-->
                    <!--                    >-->
                    <!--                    </p>-->
                </div>
                <template v-if="(product.partner || {}).url || product.categories.length > 0">
                    <div class="flex-xs-12 flex-lg-6 p0">
                        <h3
                            class="h6 text-bold text-uppercase font-roboto-c grayColor m0"
                            @click="collapseItens.relatedProduct = !collapseItens.relatedProduct"
                        >
                            <i
                                class="icon-cr collapsible-icon hidden-md-up"
                                :class="
                                    collapseItens.relatedProduct
                                        ? 'icon-chevron-right'
                                        : 'icon-chevron-down-2'
                                "
                            ></i>
                            {{ $t('_product.related') }}
                        </h3>
                        <div class="grayColor m0" :class="{ opened: collapseItens.relatedProduct }">
                            <ProductCategories id="product-categories-mobile" :product="product" />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import cr$ from '../../../common/crquery';
    import Modal from '../common/container/Modal';
    import ProductAbout from './mixin/ProductAbout';
    import ProductCredit from './ProductCredit';

    export default {
        name: 'ProductAboutForCustomer',
        components: { Modal, ProductCredit },
        mixins: [ProductAbout],

        props: {
            partner: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                collapseItens: {
                    aboutProduct: true,
                    relatedProduct: true,
                    description: true,
                    countdownDeadline: null,
                },
            };
        },
    };
</script>
