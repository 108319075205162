<template>
    <div id="cart-empty" class="mt">
        <div class="text-center mt-30">
            <p>Sua sacola <strong>está vazia.</strong></p>
            <template v-if="discount">
                <div class="frame-support">
                    <p
                        v-html="
                            $t('_cart.discountXWillBeAppliedValueYHtml', [
                                discount.name,
                                discountPrice(discount),
                            ])
                        "
                    >
                        <template v-if="this.discount.date_to">
                            ({{ $t('expiration') }}:
                            <span class="text-bold">{{ discount.date_to }}</span
                            >)
                        </template>
                        <span v-if="discount.type_id === 0">
                            ({{ $t('_shop._discount.notCumulativePromos') }})</span
                        >
                    </p>
                </div>
            </template>
            <hr class="hr-1" />
        </div>
        <div class="mt-60">
            <a href="/camiseta/"
                ><h5 class="text-center text-bold text-uppercase">Camisetas estampadas</h5></a
            >
            <hr class="hr-2 text-center" />
        </div>
        <div class="mt-30">
            <a href="/camiseta/linha-basica/"
                ><h5 class="text-center text-bold text-uppercase">Camisetas básicas</h5>
            </a>
            <hr class="hr-3 text-center" />
            <div class="text-center mt-60">
                <h2>
                    <i class="icon-cr icon-wishlist"></i>
                </h2>
                <p>
                    <a class="text-underline" href="/minha-conta/wishlist">Ver</a> minha lista de
                    desejos
                </p>
            </div>
        </div>
        <div class="mt-60">
            <hr class="hr-4" />
            <div class="text-center mt-60">
                <h3>
                    <i class="icon-cr icon-carrier-car"></i>
                </h3>
                <p>
                    <strong v-if="$scope.IS_REGULAR_CUSTOMER">Frete Grátis</strong>
                    <strong v-if="!$scope.IS_REGULAR_CUSTOMER">Envio eficiente</strong>
                </p>
                <p v-if="$scope.IS_REGULAR_CUSTOMER">
                    Frete grátis a partir de R$150* <br />
                    <span
                        >(<a
                            class="advantages-content text-underline"
                            @click="showRegulamento = true"
                            >Consulte condições</a
                        >)</span
                    >
                </p>
                <p v-if="!$scope.IS_REGULAR_CUSTOMER">
                    Enviamos seu pedido em velocidade de foguete!
                </p>
            </div>
            <div class="text-center">
                <h3>
                    <i class="icon-cr icon-exchange" v-if="$scope.IS_REGULAR_CUSTOMER"></i>
                    <i class="icon-cr icon-credit-card" v-if="!$scope.IS_REGULAR_CUSTOMER"></i>
                </h3>
                <p>
                    <strong v-if="$scope.IS_REGULAR_CUSTOMER">Troca grátis</strong>
                    <strong v-if="!$scope.IS_REGULAR_CUSTOMER">Pagamento Facilitado</strong>
                </p>
                <p v-if="$scope.IS_REGULAR_CUSTOMER">
                    Faça a sua primeira compra na Chico Rei com toda a tranquilidade
                </p>
                <p v-if="!$scope.IS_REGULAR_CUSTOMER">
                    Parcele em até 4x com cartao de crédito ou boleto bancário
                </p>
            </div>
        </div>

        <ShippingRules v-model="showRegulamento" />
    </div>
</template>

<script>
    import CartRecommendations from './recommendation/CartRecommendations';
    import { discountService } from '../../../common/service/resource';
    import { readCookie } from '../../../common/common';
    import { shippingService } from '../../../common/service/resource';
    import Modal from '../common/container/Modal.vue';
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';
    import ShippingRules from '../product/ShippingRules.vue';

    export default {
        components: { ShippingRules, Spinner, Modal, CartRecommendations },

        props: ['recommendationsTitle', 'discount'],

        data() {
            return { showRegulamento: false };
        },

        methods: {
            discountPrice() {
                let type = '';

                if (this.discount.type_id == 2 && this.discount.value > 0) {
                    type += ' dando um desconto de ' + this.$f.formatMoney(this.discount.value);
                } else if (this.discount.type_id == 1 && this.discount.value > 0) {
                    type += ' dando um desconto de ' + parseInt(this.discount.value) + '%';
                }

                if (this.discount.frete_gratis) {
                    type += (this.discount.value > 0 ? ' +' : ' gerando') + ' Frete Grátis';
                    if (this.discount.cep_frete_gratis) {
                        type += 'para o CEP' + this.discount.cep_frete_gratis;
                    }
                }

                return type;
            },
        },
    };
</script>
