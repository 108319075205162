<template>
    <ul
        v-if="products"
        @scroll="$emit('scroll', $event)"
        :class="{ 'test-ebc': products.length === 1 }"
    >
        <template v-for="index in limit">
            <ProductListItem
                v-if="index <= products.length"
                :key="products[index - 1].id"
                :options="itemOptions"
                :product="products[index - 1]"
                class="text-center"
            />
            <div v-else class="aspect aspect-shirt bg-default-1 rounded" />
        </template>
    </ul>
</template>

<script>
    import { appendQueryString, updateLazyLoad } from '../../../common/common';
    import ProductCatalogPrice from './ProductCatalogPrice';
    import ProductListItem from './ProductListItem';

    export default {
        name: 'ProductGroupList',

        components: { ProductListItem, ProductCatalogPrice },

        props: {
            limit: {
                type: Number,
                default: 8,
            },
            displayName: {
                type: Boolean,
                default: true,
            },
            showPrice: Boolean,
            showInstallments: Boolean,
            imageWidth: Number,
            showOnlyThumb: Boolean,
            hideSeals: Boolean,
            products: Array,
        },

        mounted() {
            updateLazyLoad();
        },

        computed: {
            itemOptions() {
                return {
                    displayName: true,
                    displayPrice: this.showPrice,
                    hideInstallments: !this.showInstallments,
                    displayArt: true,
                    imageWidth: this.imageWidth && this.imageWidth > 0 ? this.imageWidth : 300,
                    hideSeals: this.hideSeals,
                };
            },
        },

        methods: {
            getName(art) {
                return art.name.replace('Camiseta ', '');
            },

            getImageLink(url) {
                return appendQueryString(url, {
                    auto: 'compress,format',
                    q: 65,
                    w: 500,
                });
            },
        },
    };
</script>
