<template>
    <div v-show="show" class="search-dropdown">
        <template v-if="searchResult && searchResult.totalHits > 0">
            <div class="d-flex flex-justify-center flex-item-grow">
                <div>
                    <a
                        :href="`${$scope.URL}search?query=${searchQuery}`"
                        style="font-size: 14px"
                        class="btn center-block mt mb"
                        target="_self"
                    >
                        {{ $t('_search.toSeeAllResultsX', [searchResult.totalHits]) }}
                    </a>
                </div>
                <!--            <a-->
                <!--                :href="`${$scope.URL}search?query=${searchQuery}`"-->
                <!--                class="btn btn-block hidden-xs-up"-->
                <!--                :class="[isMobile ? 'mt-4' : '']"-->
                <!--                target="_self"-->
                <!--            >-->
                <!--                {{ $t('_search.toSeeAllX', [searchResult.totalHits]) }}-->
                <!--            </a>-->
            </div>

            <div class="search-suggestions" @mouseleave="selectedIndex = -1">
                <div
                    v-for="(hit, index) in searchResult.hits"
                    :key="hit.id"
                    :class="{ selected: selectedIndex === index }"
                    tabindex="0"
                    class="search-suggestion"
                    @click="productClick(hit)"
                    @mouseenter="selectedIndex = index"
                >
                    <div
                        class="product-list-item d-flex flex-spaced-fixed"
                        :class="[isMobile ? 'search-mobile-results' : '']"
                    >
                        <div class="search-result-thumb flex-child-fixed">
                            <div
                                v-if="!isMobile"
                                class="d-flex"
                                style="width: 285px; justify-content: flex-end"
                            >
                                <div v-if="hit.img_male && hit.img_female">
                                    <img
                                        :alt="hit.name"
                                        :src="`${hit.img_male}?auto=compress,format&q=65&w=140&h=200&fit=crop`"
                                        height="200"
                                        width="140"
                                    />
                                    <img
                                        :alt="hit.name"
                                        :src="`${hit.img_female}?auto=compress,format&q=65&w=140&h=200&fit=crop`"
                                        height="200"
                                        width="140"
                                    />
                                </div>
                                <div v-else>
                                    <img
                                        :alt="hit.name"
                                        :src="`${hit.img_thumb}?auto=compress,format&q=65&w=140&h=200&fit=crop`"
                                        height="200"
                                        width="140"
                                    />
                                    <img
                                        :alt="hit.name"
                                        :src="`${hit.img_cover}?auto=compress,format&q=65&w=140&h=200&fit=crop`"
                                        height="200"
                                        width="140"
                                    />
                                </div>
                            </div>
                            <div v-else class="d-flex">
                                <img
                                    :alt="hit.name"
                                    :src="`${hit.img_cover}?auto=compress,format&q=65&w=140&h=200&fit=crop`"
                                    height="200"
                                    width="140"
                                />
                            </div>
                        </div>

                        <div>
                            <div class="product-list-item-title">
                                {{ hit.name }}
                            </div>

                            <div v-if="hit.in_stock" class="product-list-item-price">
                                <template v-if="$scope.IS_WHOLESALER || $scope.IS_SALES_REP">
                                    {{ $f.formatMoney(hit.price_ws) }}
                                </template>
                                <template v-else-if="$scope.IS_REGULAR_CUSTOMER">
                                    <template
                                        v-if="
                                            hit.price_old > 0 &&
                                            hit.price_old > hit.price &&
                                            !(hit.price_min > 0 && hit.price_max > 0)
                                        "
                                    >
                                        <span
                                            v-if="!isMobile"
                                            class="text-accent"
                                            v-html="
                                                $t('_shop._price.fromToHtml', [
                                                    $f.formatMoney(hit.price_old),
                                                    $f.formatMoney(hit.price),
                                                ])
                                            "
                                        />
                                        <span v-else class="text-accent search-price-mobile">
                                            <span class="price-old">{{
                                                $f.formatMoney(hit.price_old)
                                            }}</span>
                                            <span class="price">{{
                                                $f.formatMoney(hit.price)
                                            }}</span>
                                        </span>
                                    </template>

                                    <span v-else>
                                        <span
                                            v-if="
                                                hit.price_min > 0 &&
                                                hit.price_max > 0 &&
                                                hit.price_min < hit.price_max
                                            "
                                            >{{ $t('_shop.priceFrom') }}
                                        </span>
                                        {{ $f.formatMoney(hit.price) }}
                                    </span>
                                </template>
                            </div>

                            <div v-else>{{ $t('unavailable') }}</div>

                            <div v-if="hit.promo_name" class="d-flex flex-align-center">
                                <div
                                    class="product-seal product-seal-primary"
                                    :class="[isMobile ? 'mt-1' : '']"
                                >
                                    Promo!
                                </div>
                                <span class="ml hidden-xs-down" style="font-size: 12px">{{
                                    hit.promo_name
                                }}</span>
                            </div>
                            <!-- <div class="search-result-breadcrumb">
                                {{ $t('in') }}:&nbsp;
                                <ul class="cr-breadcrumb mx-0 my-0">
                                    <li v-if="hit.super_type.name">
                                        <a
                                            :href="`${$scope.URL}${hit.super_type.url}/`"
                                            target="_self"
                                            >{{ hit.super_type.name }}</a
                                        >&nbsp;
                                    </li>
                                    <li v-if="hit.type.name">
                                        <a
                                            :href="`${$scope.URL}${hit.type.url}/`"
                                            target="_self"
                                            >{{ hit.type.name }}</a
                                        >&nbsp;
                                    </li>
                                </ul>
                            </div> -->

                            <!-- <div
                                v-if="
                                    hit.categories &&
                                    hit.categories.filter(
                                        (c) => c.parent_id === 5 && !c.hidden
                                    ).length > 0
                                "
                            >
                                {{ $t('categories') }}:
                                <span
                                    v-for="(
                                        category, index
                                    ) in hit.categories.filter(
                                        (c) => c.parent_id === 5 && !c.hidden
                                    )"
                                >
                                    <span v-if="index > 0">,&nbsp;</span
                                    ><a
                                        :href="`${$scope.URL}${hit.type.url}/${category.url}/`"
                                        target="_self"
                                        >{{ category.name }}</a
                                    >
                                </span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div v-else-if="searchResult && searchResult.totalHits === 0" class="px">
            <h4>{{ $t('_search.noResultsFound') }}</h4>
            <div class="categories-suggestion">
                {{ $t('_search.toSeeAllIn') }}:
                <a :href="`${$scope.URL}roupas/`" class="text-accent" target="_self">{{
                    $t('clothes')
                }}</a>
                |
                <a :href="`${$scope.URL}casa/`" class="text-accent" target="_self">{{
                    $t('housing')
                }}</a>
                |
                <a :href="`${$scope.URL}acessorios/`" class="text-accent" target="_self">{{
                    $t('accessories')
                }}</a>
            </div>
        </div>
        <div v-else-if="loading && searchQuery && searchQuery.length > 0" class="text-center">
            <Spinner />
        </div>
        <template v-else>
            <div
                class="d-flex flex-justify-between"
                :class="[isMobile ? 'search-mobile-products' : '']"
            >
                <div class="search-bar mb-4" :class="[isMobile ? 'mt-3' : 'ml-4 mt-5']">
                    <div
                        v-if="recentSearches && recentSearches.length"
                        :class="[isMobile ? 'mb-4' : '']"
                    >
                        <h5 :class="[isMobile ? '' : 'mt-0']">
                            <strong>Recente</strong>
                        </h5>
                        <ul>
                            <li v-for="search in recentSearches" @click="recentClick(search)">
                                {{ search }}
                            </li>
                        </ul>
                    </div>
                    <div
                        v-if="popularSearches && popularSearches.length"
                        :class="[isMobile ? 'mb-2' : 'mt-5']"
                    >
                        <h5><strong>Populares</strong></h5>
                        <ul>
                            <li
                                v-for="popular in popularSearches"
                                @click="goTo(popular.search_string)"
                            >
                                {{ popular.name }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="mt-5 mr-4 mb-4">
                    <a href="/inverno-2024" target="_self">
                        <img
                            v-if="!isMobile"
                            class="img-responsive"
                            src="https://chico-rei.imgix.net/images/site/header/colecao-inverno-2024.png?w=244&h=522&auto=compress,format&q=65"
                            alt="Irmão do Jorel"
                        />
                        <img
                            v-else
                            class="img-responsive"
                            src="https://chico-rei.imgix.net/images/site/header/colecao-inverno-2024.png?auto=compress,format&q=65"
                            alt="Irmão do Jorel"
                        />
                    </a>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import Spinner from '@bit/chicorei.chicomponents.feedback.spinner';
    import { scrollToPosition, storageWrapper } from '../../../common/common';
    import CartKit from '../cart/special-promo/CartKit.vue';

    export default {
        name: 'Search',

        components: { CartKit, Spinner },

        props: {
            show: {
                type: Boolean,
                default: false,
            },
            searchQuery: {
                type: String,
                default: '',
            },
            isMobile: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                resultsCache: {},
                searchResult: null,
                loading: false,
                selectedIndex: -1,
                recentSearches: (JSON.parse(storageWrapper.getItem('recentSearches')) || []).slice(
                    -5
                ),
                popularSearches: null,
            };
        },

        created: function () {
            this.$parent.$on('input-up', this.selectPreviousSuggestion);
            this.$parent.$on('input-down', this.selectNextSuggestion);
            this.$parent.$on('input-enter', this.enterSelectedSuggestion);
            this.fetchPopularSearches();
        },
        watch: {
            searchQuery(to) {
                this.scrollTop();
                this.doSearch(to);
            },
        },

        methods: {
            scrollTop() {
                if (window.innerWidth <= 768) {
                    scrollToPosition(0, 0, 400);
                }
            },

            doSearch(query) {
                if (!query || query.length < 1) {
                    this.searchResult = null;
                    return;
                }
                query = query.toLowerCase();

                if (this.resultsCache[query]) {
                    this.searchResult = this.resultsCache[query];
                    return;
                }

                this.loading = true;
                this.searchResult = null;
                this.selectedIndex = -1;

                this.axios.http
                    .get(this.$scope.API_LAMBDA_URL + '/products/v4/search', {
                        params: {
                            query: query,
                            onlyInStock: false,
                            listLocked: true,
                            attributes: 'search-box',
                            orderBy: 'relevancia',
                            wholesaler: !this.$scope.IS_REGULAR_CUSTOMER,
                            tenant: this.$scope.TENANT_IDENTIFIER,
                        },
                    })
                    .then((response) => {
                        this.resultsCache[query] = response.data;
                        if (this.searchQuery.toLowerCase() === query) {
                            this.searchResult = this.resultsCache[query];
                            this.loading = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            fetchPopularSearches() {
                this.loading = true;
                this.popularSearches = this.$scope.POPULAR_SEARCHES;
                this.loading = false;
            },

            productClick(product) {
                let link = product.link;
                let gender = product.img_cover_gender;
                window.location.href = link + (gender ? '?gender=' + gender : '');
            },

            recentClick(recent) {
                window.location.href = this.$scope.URL + ('search?query=' + recent);
            },

            goTo(link) {
                window.location.href = link;
            },

            selectPreviousSuggestion() {
                if (!this.searchResult) return;
                this.selectedIndex = Math.max(-1, this.selectedIndex - 1);
            },

            selectNextSuggestion() {
                if (!this.searchResult) return;
                this.selectedIndex = Math.min(
                    this.searchResult.hits.length - 1,
                    this.selectedIndex + 1
                );
            },

            enterSelectedSuggestion(event) {
                if (!this.searchResult || this.selectedIndex < 0) return;
                if (event) event.preventDefault();
                this.productClick(this.searchResult.hits[this.selectedIndex]);
            },
        },
    };
</script>
