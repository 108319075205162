<template>
    <div
        id="header-desktop-detail-wrapper"
        class="toggleable pos-bottom pos-left pos-right"
        :class="{
            open: openedItem && (openedItem.children || []).length > 0,
            closed: !openedItem,
            debug,
        }"
    >
        <div class="header-overlay" @click="debug ? null : $emit('close')" />
        <!--header-detail-->
        <div
            class="pos-absolute pos-left pos-right bg-header-menu pt-4 pb"
            style="margin-top: -14px"
            @mouseleave="debug ? null : $emit('close')"
        >
            <HeaderDesktopContentSection
                v-if="openedItem"
                :key="openedItem.id"
                :item="openedItem"
                :open="true"
            />
            <div class="content px py d-flex" style="justify-content: space-evenly">
                <div class="text-center mr">
                    <div class="d-flex flex-justify-center mb">
                        <i class="icon-cr icon-carrier-car"></i>
                    </div>
                    <span class="d-flex flex-column text-menu" style="line-height: 14px">
                        <span class="text-bold" v-if="$scope.IS_REGULAR_CUSTOMER"
                            >Frete Grátis</span
                        >
                        <span class="text-bold" v-if="!$scope.IS_REGULAR_CUSTOMER"
                            >Envio Eficiente</span
                        >
                        <span v-if="$scope.IS_REGULAR_CUSTOMER"
                            >Frete grátis a partir de R$150* <br />
                            (Consulte condições na sacola de compras)</span
                        >
                    </span>
                </div>
                <div class="text-center ml">
                    <div class="d-flex flex-justify-center mb">
                        <i class="icon-cr icon-exchange" v-if="$scope.IS_REGULAR_CUSTOMER"></i>
                        <i class="icon-cr icon-credit-card" v-if="!$scope.IS_REGULAR_CUSTOMER"></i>
                    </div>
                    <span class="d-flex flex-column text-menu" style="line-height: 14px">
                        <span class="text-bold" v-if="$scope.IS_REGULAR_CUSTOMER"
                            >Troca Grátis</span
                        >
                        <span class="text-bold" v-if="!$scope.IS_REGULAR_CUSTOMER"
                            >Pagamento Facilitado</span
                        >
                        <span v-if="$scope.IS_REGULAR_CUSTOMER"
                            >Faça a sua primeira compra na Chico Rei<br />
                            com toda a tranquilidade</span
                        >
                        <span v-if="!$scope.IS_REGULAR_CUSTOMER"
                            >Parcele em até 4x com cartao de crédito ou boleto bancário</span
                        >
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderDesktopContentItems from './HeaderDesktopContentItems';
    import HeaderDesktopContentSection from './HeaderDesktopContentSection';
    import HeaderDesktopContentThumbs from './HeaderDesktopContentThumbs';

    export default {
        name: 'MenuItemDetail',

        components: {
            HeaderDesktopContentThumbs,
            HeaderDesktopContentItems,
            HeaderDesktopContentSection,
        },

        props: {
            items: {
                type: Array,
                required: true,
            },
            openedItem: Object,
            debug: Boolean,
        },
    };
</script>
